import './style.css';
import noiseTextureImg from './metadata/noisy-texture-300x300.png'; 
import confidentialImg from './metadata/confidentialWtr.png';
import bidenPortrait from './metadata/220224-joe-biden-jm-1449-63f255.jpg';
import pfpImg from './metadata/pfpTransparent.png';
import meme1Img from './metadata/memes/1.jpeg';
import meme2Img from './metadata/memes/2.jpg';
import meme3Img from './metadata/memes/6.jpeg';
import meme4Img from './metadata/memes/4.jpeg';
import meme5Img from './metadata/memes/3.jpeg';


function filmGrain() {
  const filmGrainContainer = document.createElement('div');
  filmGrainContainer.id = "filmGrain";

  const imgWidth = 300;
  const imgHeight = 300;
  const elementWidth = document.body.offsetWidth;
  const elementHeight = document.body.offsetHeight; 
  const reqRows = Math.ceil(elementWidth/imgWidth);
  const reqCols = Math.ceil(elementHeight/imgHeight);

  for (let col = 0; col <= reqCols; col++) {
    const noiseRow = document.createElement('div');
    noiseRow.classList.add("row");

    for (let row = 0; row <= reqRows; row++) {
      const noiseTexture = document.createElement('img');
      noiseTexture.src = noiseTextureImg;
      noiseTexture.classList.add("noiseTexture");
      noiseRow.appendChild(noiseTexture);
    }

    filmGrainContainer.appendChild(noiseRow);
  }
  document.body.appendChild(filmGrainContainer);
}

const landingPage = document.getElementById('landingPage');

const landingHeader = document.createElement('span');
landingHeader.classList.add("header");
landingHeader.innerHTML = `CENTRAL INTELLIGENCE BULLETIN`;
landingPage.appendChild(landingHeader);

const personInfoContainer = document.createElement('div');
personInfoContainer.id = "personInfo";

const personPortrait = document.createElement('img');
personPortrait.src = bidenPortrait;

const scrapPortrait = document.createElement('div');
scrapPortrait.id = "scrapPortrait";
personInfoContainer.appendChild(scrapPortrait);

const personInfoText = document.createElement('div');
personInfoText.id = "personInfoText";

const personInfoSpan = document.createElement('span');
personInfoSpan.innerHTML = `
  Name: Joseph Robinette Biden Jr.
  <br>
  Alias: Sleepy Joe
  <br>
  Date of birth: 00-00-0000
  <br>
  Nationality: North American
  <br>
  Service: Lawyer, U.S. Senate
  <br>
  Weight: 200lbs
  <br>
  Height: 200lbs
  <br>
  Parents: Catherine Finnegan, Joseph R. Biden Sr.
  <br>
  Children: Hunter Biden, Beau Biden
  <br>
  Current residence: White House
  <br>
  Marks: somethingsomething
  <br>
  Eye color: Blue`;

const scrap1 = document.createElement('div');
scrap1.classList.add("scrap");
scrap1.classList.add("scrap1");
const scrap2 = document.createElement('div');
scrap2.classList.add("scrap");
scrap2.classList.add("scrap2");
const scrap3 = document.createElement('div');
scrap3.classList.add("scrap");
scrap3.classList.add("scrap3");
const scrap4 = document.createElement('div');
scrap4.classList.add("scrap");
scrap4.classList.add("scrap4");
const scrap5 = document.createElement('div');
scrap5.classList.add("scrap");
scrap5.classList.add("scrap5");
const scrap6 = document.createElement('div');
scrap6.classList.add("scrap");
scrap6.classList.add("scrap6");
const scrap7 = document.createElement('div');
scrap7.classList.add("scrap");
scrap7.classList.add("scrap7");
const scrap8 = document.createElement('div');
scrap8.classList.add("scrap");
scrap8.classList.add("scrap8");

personInfoText.appendChild(scrap1);
personInfoText.appendChild(scrap2);
personInfoText.appendChild(scrap3);
personInfoText.appendChild(scrap4);
personInfoText.appendChild(scrap5);
personInfoText.appendChild(scrap6);
personInfoText.appendChild(scrap7);
personInfoText.appendChild(scrap8);

personInfoContainer.appendChild(personPortrait);
personInfoText.appendChild(personInfoSpan);
personInfoContainer.appendChild(personInfoText);
landingPage.appendChild(personInfoContainer);

const closeLandingBtn = document.createElement('button');
closeLandingBtn.id = "closeLandingBtn";
closeLandingBtn.innerHTML = `Join cabal`;
landingPage.appendChild(closeLandingBtn);

const landingConfidentialText = document.createElement('span');
landingConfidentialText.classList.add("confidentialSpan");
landingConfidentialText.innerHTML = `CONFIDENTIAL`;
landingPage.appendChild(landingConfidentialText);

const mainPage = document.getElementById('mainPage');

const main = document.querySelector('main');

const header = document.createElement('img');
header.id = "headerImg";
header.src = pfpImg;
main.appendChild(header);
const headerText = document.createElement('span');
headerText.id = "headerImgText";
headerText.innerHTML = `CRUST NOBODY!`;
main.appendChild(headerText);

const pizzaText = document.createElement('span');
pizzaText.id = "pizzaText";
pizzaText.innerHTML = `
  "hotdog" = boy
  <br>
  "pizza" = girl
  <br>
  "cheese" = little girl
  <br>
  "pasta" = little boy
  <br>
  "ice cream" = male prositute
  <br>
  "walnut" = person of colour
  `;

main.appendChild(pizzaText);

const meme1 = document.createElement('img');
meme1.src = meme1Img;
meme1.id = "meme1";
main.appendChild(meme1);
const meme2 = document.createElement('img');
meme2.src = meme2Img;
meme2.id = "meme2";
main.appendChild(meme2);
const meme3 = document.createElement('img');
meme3.src = meme3Img;
meme3.id = "meme3";
main.appendChild(meme3);
const meme4 = document.createElement('img');
meme4.src = meme4Img;
meme4.id = "meme4";
main.appendChild(meme4);
const meme5 = document.createElement('img');
meme5.src = meme5Img;
meme5.id = "meme5";
main.appendChild(meme5);

const actions = document.createElement('div');
actions.classList.add("actions");

const telegramBtn = document.createElement('button');
telegramBtn.id = "telegram-button";
telegramBtn.classList.add("backBlur");
telegramBtn.innerHTML = `TELEGRAM`;
actions.appendChild(telegramBtn);

const twitterBtn = document.createElement('button');
twitterBtn.id = "twitter-button";
twitterBtn.classList.add("backBlur");
twitterBtn.innerHTML = `TWITTER`;
actions.appendChild(twitterBtn);

const chartBtn = document.createElement('button');
chartBtn.id = "chart-button";
chartBtn.classList.add("backBlur");
chartBtn.innerHTML = `DEXSCREENER`;
actions.appendChild(chartBtn);

const buyBtn = document.createElement('button');
buyBtn.id = "buy-button";
buyBtn.classList.add("backBlur");
buyBtn.innerHTML = `BUY $PIZZAGATE`;
actions.appendChild(buyBtn); 

main.appendChild(actions);


const footer = document.querySelector('footer');
footer.classList.add("backBlur");

const contract = document.createElement('span')
contract.innerHTML = `E3V31xnKzv5W2iv5bDgtAwvyMq5emVv3aP1XVFzzyBxJ`;
footer.appendChild(contract);

const copyCaBtn = document.createElement('button');
copyCaBtn.innerHTML = `Copy`;
footer.appendChild(copyCaBtn);

document.addEventListener('DOMContentLoaded', function() {
  filmGrain();

  landingPage.style.display = 'flex';
  mainPage.style.display = 'none';

  closeLandingBtn.addEventListener('click', function() {
    landingPage.style.display = 'none';
    mainPage.style.display = 'flex';
  });

  // LINKS
  twitterBtn.addEventListener('click', function() {
    window.open('https://x.com/PizzaGateSolana', '_blank');
  });
  telegramBtn.addEventListener('click', function() {
    window.open('https://t.me/PIZZAGATED', '_blank');
  });
  chartBtn.addEventListener('click', function() {
    window.open('https://dexscreener.com/solana/gfcgjtoat47eyfpyhesxw9twrr3eeu2he3jhizz4bthd', '_blank');
  });
  buyBtn.addEventListener('click', function() {
    window.open('https://www.pump.fun/E3V31xnKzv5W2iv5bDgtAwvyMq5emVv3aP1XVFzzyBxJ', '_blank');
  });

  // Copy Btn Logic
  const copyButton = copyCaBtn;
  const textToCopy = contract.innerHTML;
  copyButton.addEventListener("click", async () => {
    try {
      // Try the modern Clipboard API first (if supported)
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied successfully using Clipboard API");
      alert("Contract copied successfully!");
    } catch (err) {
      // If Clipboard API fails, use the legacy approach
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed"; // Hide element off-screen
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied successfully using legacy approach");
      alert("Contract copied successfully!");
    }
  });

});